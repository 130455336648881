<template>
  <v-app>
    <default-bar />
    <default-view />
    <!-- <default-drawer /> -->
    <default-footer />
  </v-app>
</template>

<script>
  import { get, sync } from 'vuex-pathify'
  export default {
    name: 'DefaultLayout',
    components: {
      DefaultBar: () =>
      import(
        /* webpackChunkName: "default-app-bar" */
        './Loading'
      ),
      DefaultDrawer: () =>
      import(
        /* webpackChunkName: "default-drawer" */
        './Drawer'
      ),
      DefaultFooter: () =>
      import(
        /* webpackChunkName: "default-footer" */
        './Footer'
      ),
      DefaultSettings: () =>
      import(
        /* webpackChunkName: "default-settings" */
        './Settings'
      ),
      DefaultView: () =>
      import(
        /* webpackChunkName: "default-view" */
        './View'
      ),
    },
    data: () => ({
      active: false,
      opacity: 1,
    }),
    methods: {},
    computed: {
      ...sync('app', ['drawer', 'mini']),
      name: get('route/name'),
    },
  }
</script>
<style lang="scss"></style>
